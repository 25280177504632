@import "mixin";

.form__section {
  @include flex();

  .form-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .form__row {
      display: flex;
      gap: 1rem;
    }

    .mat-form-field {
      width: 100%;
    }

    .out--side__label {
      display: flex;
      margin-bottom: 0.75rem;

      .span--required {
        color: $required;
      }
    }

    .title--field {
      @include flex($justify-content: flex-start, $align-items: flex-end);
      gap: 0.5rem;
      margin-bottom: 0.75rem;

      .out--side__label {
        margin-bottom: initial
      }

      .description {
        @include font-size($x-small);
        color: $dark-gray;
      }
    }
  }

  .form-actions {
    margin-top: 2rem;
    @include flex();
    gap: 1rem;
  }
}

@mixin select {
  .cdk-overlay-container {
    .cdk-overlay-pane {
      .mat-select-panel-wrap {

        .select--search__panel,
        .select__panel {
          background-color: $very-dark-gray6;

          &.multiple {
            .mat-option {
              &.mat-selected {
                background-color: transparent;
              }
            }
          }

          .form__section {
            background-color: $very-dark-gray9;

            // input type search
            .mat-form-field-type-mat-input {
              width: 100%;

              .mat-form-field-wrapper {
                .mat-form-field-flex {
                  background-color: $very-dark-gray6;
                }
              }
            }
          }

          .box--value {
            .divider {
              border-top: 1px solid $very-dark-gray10;
            }
          }
          .mat-checkbox {
            .mat-checkbox-frame {
              border-color: $dark-gray;
            }
          }

          .mat-option {
            .mat-pseudo-checkbox {
              color: $dark-gray;
            }

            &:hover {
              background-color: $very-dark-gray9;
            }

            &.mat-selected {
              background-color: $line-active-dark;
            }
          }
        }
      }
    }
  }
}

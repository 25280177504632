.cdk-overlay-pane {
  .mat-snack-bar-container {
    width: fit-content;
    max-width: fit-content;
    padding: 0;
    border-radius: 8px;
    color: currentColor;
    background: none;
    box-shadow: none;
    background: transparent;
  }
}
.privacy-terms-content-wrapper {
    height: 100%;
    padding: 1.5rem;
    padding-right: 0;
    background-color: $white;
    border-radius: 8px;
    position: relative;

    .privacy-terms-content {
        height: 100%;
        background-color: $white;
        line-height: 2.5rem;

        overflow-x: hidden;
        overflow-y: scroll;

        h1,
        h2,
        h3,
        h4,
        h5 {
            text-align: left;
        }

        h3 {
            padding: 0.5rem 0;
        }

        h4, h4+p, ol+p {
            margin-left: 1rem;
        }

        h4+ol, h4+p+ol, ol+p+ol {
            margin-left: 2rem;
        }

        ol,
        ul {
            &:not([type]) li {
                margin-left: 2rem;
            }
            &[type="a"] li {
                margin-left: 1.25rem;
            }
            &[type="i"] li {
                margin-left: 1rem;
            }
        }

        a:hover {
            color: $secondary;
        }
    }

    &.theme--dark {
        color: $white;
        background-color: $very-dark;

        .privacy-terms-content {
            color: $white;
            background-color: $very-dark;
        }
    }
}

@mixin font {
  h1,
  h2,
  h3,
  h4,
  h5,
  a,
  span,
  p,
  label,
  textarea {
    color: $white;

    &.gray--color {
      color: $dark-gray;
    }
  }

}

@import "mixin";

.mat-datepicker-popup {
  .mat-datepicker-content {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    margin-top: 0.25rem;
    background-color: $very-light-gray1;

    @include mat-calendar-custom();
  }
}

.date--picker__section {
  .date--wrapper {
    .mat-form-field-wrapper {
      padding: 0;

      .mat-form-field-flex {
        padding: 0;
        border: 1px solid transparent;
        border-radius: 8px;

        &:hover {
          border-color: $secondary;
        }

        .mat-form-field-infix {
          padding: 0;
          border-top: 0;

          // override material's line-height default (1.25)
          // 1. Not working correctly except chrome
          //    start-date is displaying without base-liine with end-date
          // 2. Not working corectly even though on chrome
          //    both start and date is able to scroll on input area
          // Solution: define clearly line-height and font-size
          .mat-date-range-input-container {
            @include font-size($normal);
          }
        }
      }
    }

    &.mat-focused {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          border-color: $secondary;
        }
      }
    }

    &.mat-form-field-invalid {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          border-color: $error;
        }
      }
    }

    mat-datepicker-toggle {
      button {
        &:hover {
          background: initial;
        }
      }

      &.mat-datepicker-toggle-active {
        .icon-icon_calendar::before {
          color: $secondary;
        }
      }
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-subscript-wrapper {
      padding: 0;

      .mat-error {
        margin-top: 1rem;

        p {
          @include font-size($x-small);
          color: $error;
        }
      }
    }
  }
}

@import "variables";
@import "mixin";

.paginator {
  @include flex();

  .paginator-page-size {

    .mat-paginator-container {
      min-height: auto;

      .mat-paginator-page-size {
        display: flex;
        align-items: center;
        margin-right: 0px;

        .mat-paginator-page-size-label {
          padding-left: 20px;
          padding-right: 12px;
          font: 'Noto Sans JP, Regular';
          font-size: $normal;
        }
      }
    }


    .mat-form-field {
      margin: 0;

      &.mat-paginator-page-size-select {
        width: 70px;
        background: $background_select;
        height: 48px;
        border-radius: 8px;
      }

      .mat-form-field-wrapper {
        padding: 12px;
        border: none;

        .mat-form-field-infix {
          border: none;

          .mat-select-value {
            width: 75%;
            font-family: 'Noto Sans JP';
            font-size: $normal;
          }

          .mat-select-arrow-wrapper {
            background: url('/assets/images/pagination/arrow-down.svg') 50% 50% no-repeat;
            background-size: 16px 16px;
          }
          .mat-select-arrow {
            margin: 0;
            border-top: 0px;

          }
        }
      }
    }

    .mat-paginator-range-actions,
    .mat-form-field-appearance-legacy .mat-form-field-underline {
      display: none;
    }
  }

  .paginator-actions {
    @include flex($justify-content: flex-end);

    button {
      height: 32px;
      padding: 0;
      width: 32px;
      margin-left: 8px;

      .mat-icon {
        color: white;
      }

      &[disabled] {
        background-color: $white;
        .mat-icon {
          color: $gray4;
        }
      }

      &:not(:disabled) {
        background-color: $white;
        color: $very-dark;
      }

      &:hover {
        box-shadow: none;
        opacity: 1;
        background-color: $very-light-gray;
        border-radius: 32px;
      }
    }

    .paginator-page-numbers {
      @include flex();
      margin-right: 8px;
      margin-left: 8px;

      .paginator-page-number {
        width: 2rem;
        height: 2rem;
        @include flex();
        margin-left: 8px;

        &.clickable:hover {
          cursor: pointer;
          background-color: $very-light-gray;
          border-radius: 32px;
        }

        &.active {
          border-radius: 30px;
          background-color: $very-dark;
          color: $white;
          font-weight: bold;
        }
      }
    }

    .icon_pagination::before {
      font-size: $size-icon;
      margin-right: 0;
    }
  }
}

@import "variables";

$size-toggle: 1.125rem;
$height-slide: 1.5rem;
$width-slide: 2.75rem;

.mat-slide-toggle {
  .mat-slide-toggle-bar {
    height: $height-slide;
    width: $width-slide;
    border-radius: 1rem;
    background-color: $gray;

    .mat-slide-toggle-thumb-container {
      top: 0.188rem;
      left: 0.188rem;

      .mat-slide-toggle-thumb {
        width: $size-toggle;
        height: $size-toggle;
      }
    }
  }

  &.mat-checked {
    .mat-slide-toggle-bar {
      // override mat
      background-color: $secondary !important;

      .mat-slide-toggle-thumb-container {
        left: 0.375rem;

        .mat-slide-toggle-thumb {
          background-color: white;
        }
      }
    }
  }
}

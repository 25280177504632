
@mixin slide-toggle {
  .mat-slide-toggle {
    .mat-slide-toggle-bar {
      background-color: $dark-gray;
    }

    &.mat-checked {
      .mat-slide-toggle-bar {
        .mat-slide-toggle-thumb-container {
          .mat-slide-toggle-thumb {
            background-color: $white;
          }
        }
      }
    }
  }
}

.mat-checkbox {
  .mat-checkbox-layout {
    align-items: initial;

    .mat-checkbox-inner-container {
      margin: initial;
      margin-right: 0.5rem;
    }

    .mat-checkbox-label {
      white-space: pre-line;
    }
  }
}

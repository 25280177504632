
@mixin utilities {
  .border--site__vertical {
    $value: 1px solid $very-dark-gray5;
    border-top: $value;
    border-bottom: $value;
  }
}

body.dark {
  .mat-form-field-appearance-fill {
    .mat-form-field-wrapper {
  
      // default setting
      .mat-form-field-flex {
        input, textarea {
          &.mat-input-element {
            caret-color: $white;
            &::placeholder {
              color: $dark-gray;
            }
          }
        }
      }
    }
  }

  /** INPUT TYPE='color' - CUSTOM STYLING **/
  div.input-color {

    &:hover {
      border: 1px solid #25262a;
      box-shadow: 0px 0px 8px #ffffff4d;
    }
  }
}

@import "variables";
@import "mixin";
$width-site: 380px;

.right-sidebar-container {
  &.mat-drawer-container {
    position: fixed;
    width: fit-content;
    height: 100vh;
    right: 0;
    top: 0;
    z-index: 100;
    box-shadow: 0 0 $normal rgb(0 0 0 / 10%);
    overflow: hidden;
    background-color: $white;
  }

  .right-sidebar {
    width: $width-site;
    border: 0;
    box-shadow: 0 0 2rem $black;

    .mat-drawer-inner-container {
      overflow: hidden;
    }

    .right-sidebar-content {
      @include flex-column();
      height: calc(100vh - 154px);
      overflow: hidden;
      padding-left: 1.5rem;

      .right-sidebar-search {
        padding-top: $xs-large;
        padding-bottom: $normal;
        padding-right: $xs-large;
        width: 100%;
      }

      .right-sidebar-items-block {
        flex: 1;

        .right-sidebar-items-list {
          height: 100%;

          .right-sidebar-items-length {
            padding-right: $xs-large;

            .items-length-info {
              @include total-info-block(true);
              padding-bottom: $small;
              border-bottom: 1px solid $light-gray4;
            }
          }

          .right-sidebar-item-wrapper {
            padding-right: $x-small;
            padding-top: $small;

            .right-sidebar-item {
              display: flex;
              align-items: center;
              width: 100%;
              position: relative;
              padding: $x-small;


              &.right-sidebar-item--dark {
                .item-right {
                  .info {
                    .info__name {
                      color: $white;
                    }
                  }
                }

                &:hover {
                  background-color: $row-hover-dark;

                  .right-sidebar-item__remove {
                    &:hover {
                      background-color: $icon-action-hover-dark;
                    }
                  }
                }
              }

              &.none-padding-left {
                padding-left: 0;
              }

              .item-left {
                flex: 3;
                padding-right: $tiny;
                @include flex-center();
              }

              .item-right {
                flex: 10;

                .info {
                  .info__name {
                    @include font-size($normal);
                    font-weight: bold;
                    color: $black;
                  }
                }
              }

              &:hover {
                background-color: $row-hover-light;
                border-radius: $small;

                .right-sidebar-item__remove {
                  display: inline-flex;
                  padding: 7px;

                  &:hover {
                    background-color: $icon-action-hover-light;
                  }
                }
              }

              .right-sidebar-item__remove {
                display: none;
                position: absolute;
                right: $medium;
                border-radius: 50%;
                cursor: pointer;

                .icon-close {
                  font-size: $xxs-small;
                  font-weight: $semi-bold-weight
                }
              }
            }
          }

        }

        .right-sidebar-item-empty {
          font-size: $medium;
        }
      }
    }

    .right-sidebar-close {
      padding: 1rem;
      text-align: end;
      border-bottom: 1px solid $light-gray4;
    }

    // normal action area
    .right-sidebar-actions {
      @include flex($justify-content: space-between);
      border-top: 1px solid $light-gray4;
      padding: 1.5rem;

      // only 1 action on right edge
      .right-sidebar-actions--right {
        width: 100%;
        @include flex($justify-content: flex-end);
      }

      // with cancel action
      &.with-cancel {
        @include flex($justify-content: flex-end);
        gap: 0.5rem;
      }
    }


    // [THEME DARK] - styling 
    &.theme--dark {
      background-color: $very-dark-gray3;

      .right-sidebar-content {
        .right-sidebar-items-block {

          .right-sidebar-items-list {

            .right-sidebar-items-length {
              .items-length-info {
                @include total-info-block(false);
                border-bottom-color: $very-dark-gray5;
              }
            }
          }
        }
      }

      .right-sidebar-close {
        border-bottom-color: $very-dark-gray5;
      }

      .right-sidebar-actions {
        border-top-color: $very-dark-gray5;
      }
    }
  }
}
h1 {
  text-align: center;
  font-size: 2rem;
}

h3 {
  font-size: 1.2rem;
  text-align: center;
}


h1,
h2,
h3,
h4,
h5,
a,
span,
p,
label,
textarea {
  color: $black;

  &.gray--color {
    color: $dark-gray;
  }
}

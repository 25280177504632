@import "variables";

$size-action: 6rem;
$size-padding: 2rem;

.dialog-fields {
  padding: $size-padding;

  .dialog-field-form {
    margin-bottom: $size-action;

    form {
      width: 100%;

      input {
        border: 2px solid transparent;
        border-radius: 6px;
        width: -webkit-fill-available;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        -webkit-text-fill-color: initial;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }
}

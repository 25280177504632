@import 'variables';

table {
  width: 100%;

  tbody {
    // break word on column text, not apply on action
    td:not(.mat-column-action) {
      word-break: break-all;
    }

    td.mat-cell {
      &:last-of-type {
        padding-right: 0px;
      }
    }

    .normal-text {
      font-size: $normal;
    }

    .link-text {
      font-size: $normal;

      a {
        color: $secondary;
        text-decoration: underline;

        &:hover {
          color: $very-blue-1;
        }
      }
    }

    .status-text {
      font-size: $normal;
      display: flex;
      align-items: center;

      i {
        margin-right: 0.5rem;
      }

      p {
        font-size: $medium;
        &.lime-green {
          color: $lime-green;
        }
      }
    }

    .table-actions {
      display: flex;
      justify-content: space-evenly;

      .icon-table {
        padding: 12px;
        border-radius: 50%;

        &::before {
          font-size: $size-icon;
        }

        &:hover {
          background: $icon-action-hover-light;
        }
      }
    }
  }

  th.mat-header-cell,
  td.mat-cell {
    &:first-of-type {
      padding-left: 0.75rem;
    }

    &.padding-right {
      padding-right: 2.5rem;
    }
  }

  th.mat-header-cell {
    font-size: $normal;
    font-weight: bold;
    color: $black;
    padding-bottom: 0.75rem;

    &.clicked {
      cursor: auto;
    }
  }

  tr.mat-row,
  tr.mat-footer-row {
    min-height: 60px;
    width: 100%;

    // line active
    &.highLight {
      background: $line-active-light;
    }
  }

  // line hover and not apply as hightlight
  tr.mat-row {
    &:not(.highLight):hover {
      background: $row-hover-light;
    }

    .empty-data {
      padding-left: 0.75rem;
      padding-top: 0.75rem;
      font-size: $normal;
    }

    &.empty:hover {
      background: none;
    }
  }

  tr.mat-header-row {
    height: auto;
    .mat-header-cell {
      .table-header-content {
        .table-header-label {
          display: inline-block;
        }

        .table-header-icon {
          display: inline-flex;
          flex-direction: column;
          position: absolute;
          padding: 4px 0 2px 8px;

          i {
            display: block;

            &.icon-sort_table_asc,
            &.icon-sort_table_desc {
              font-size: $xxs-small;
              line-height: 0.9;
            }

            &.disable {
              color: $dark-gray;
            }
          }
        }
      }
    }
  }
}

.overlay__scroll {
  height: 1.5rem;
  width: 100%;
  position: absolute;
  z-index: 50;
  bottom: 0;
  padding-bottom: 2rem;
  padding-top: 2rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  pointer-events: none;
}

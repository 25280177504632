$min-height-button: 10rem;

button {
  @include flex();
  background: $black;
  color: $white;
  border: 0;
  gap: 0.75rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;

  appearance: none;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  touch-action: manipulation;
  vertical-align: middle;
  user-select: none;
  will-change: transform, opacity;
  padding: 0.75rem 2rem;
  -webkit-user-select: none;

  font-size: $normal;
  line-height: $line-height-normal;

  &.button--primary {
    background: transparent;
    color: $black;
    border: 1px solid $gray;

    &:hover {
      color: $white;
    }
  }

  &:hover {
    background: $very-dark-gray;
  }

  &:disabled,
  [disabled] {
    cursor: default;
    pointer-events: none;
    background: $gray;
    color: $dark-gray;
  }

  &.button--small {
    // TODO:
  }

  &.button--large {
    font-size: $xxs-large;
    line-height: $line-height-xxs-large;
    padding-top: 17px;
    padding-bottom: 17px;
  }

  i {
    &::before {
      font-size: $size-icon;
    }
  }
}

.button--secondary,
.button--secondary--bold {
  background: transparent;
  padding: 0.75rem 2rem;
  color: $black;
  border-radius: 8px;
  font-weight: normal;

  &:hover {
    background: $white;
  }

  i {
    &::before {
      font-size: $size-icon;
    }
  }

  &.out--side__modal {
    &:hover {
      background: $very-light-gray;
    }
  }
}

.button--secondary--bold {
  font-weight: bold;
}

.button--icon {
  &:hover {
    background-color: $light-gray3;
  }
}

@import "variables";

.input__icon {
  display: flex;
}

.form__section {
  &.search-box__form {
    .mat-form-field-appearance-fill {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          padding-left: 48px;
        }
      }
    }
  }

  .mat-form-field-appearance-fill {
    .mat-form-field-wrapper {
      padding-bottom: 0;

      // default setting
      .mat-form-field-flex {
        border-radius: 8px;
        border: 1px solid $input-outline-light;
        background-color: transparent;
        box-shadow: none;

        padding: 0.75rem 1rem;

        line-height: $line-height-normal;
        height: auto;

        .mat-form-field-infix {
          padding: 0;
          border-top: 0;

          .mat-form-field-label-wrapper {
            padding: 0;
            pointer-events: none;
          }
        }
      }

      // override: none-underline
      .mat-form-field-underline {
        display: none;
      }

      // override: material error area
      .mat-form-field-subscript-wrapper {
        padding: 8px 0 0 0;
        margin-top: 0;
        pointer-events: none;

        .mat-error {
          @mixin font-error {
            color: $input-outline-invalid;
            @include font-size($medium);
            font-weight: normal;
          }

          @include font-error();

          p {
            @include font-error();
          }
        }

        // override: disabled color
        &.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-flex {
          background-color: $input-disable-light;
          border-color: transparent;
        }
      }
    }

    // override: focus color
    &.mat-focused .mat-form-field-wrapper {
      .mat-form-field-flex {
        border-color: $input-outline-focus;
        box-shadow: 0px 0px 16px $input-outline-focus-shadow;
      }
    }

    // override: valid color as default (onblur)
    &:is(.ng-valid):not(.mat-focused) .mat-form-field-wrapper {
      .mat-form-field-flex {
        border-color: $input-outline-light;
      }
    }

    // override: invalid color (error)
    &:is(.ng-invalid):not(.mat-focused):not(.ng-untouched) .mat-form-field-wrapper {
      .mat-form-field-flex {
        border-color: $input-outline-invalid;
      }
    }

    // override: hover color (box-shadow)
    // not apply when disable, focus, error
    &:not(.mat-form-field-disabled):not(.mat-focused):not(.ng-invalid) {
      .mat-form-field-wrapper .mat-form-field-flex:hover {
        box-shadow: 0px 0px 16px $input-outline-hover-shadow-light;
      }
    }
  }

  // define custom styling base on component type
  // in case input is editing inline in title on such as modal
  .title__inline {
    .mat-form-field-appearance-fill {
      .mat-form-field-wrapper {

        // default setting
        .mat-form-field-flex {
          padding-top: 1rem;
          padding-bottom: 1rem;
          border-width: 2px;
          line-height: $line-height-xxs-large;
        }
      }
    }
  }

  // validate
  .mat-form-field-invalid {
    .mat-form-field-wrapper {
      padding-bottom: $wrapper-padding-bottom;
    }
  }

  // disabled
  .mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-flex {
    background-color: $gray;
    opacity: 0.7;
  }
}

$hover-color: #19191A;

@mixin date-picker {
  .mat-datepicker-popup {
    .mat-datepicker-content {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.30);
      background-color: $very-dark-gray3;

      @include mat-calendar-custom-dark($hover-body-cell-content: $hover-color);
    }
  }
}

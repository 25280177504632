/**
 * Please replace id file
 */
@font-face {
  font-family: 'icomoon';
  src: url('../icons/icomoon/icomoon.ttf?crl4e9') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arrow_down_after:after {
  content: "\e918";
}
.icon-group_name_small:before {
  content: "\e967";
  color: #7f7f7f;
}
.icon-summary_name_small:before {
  content: "\e968";
  color: #7f7f7f;
}
.icon-menu:before {
  content: "\e965";
}
.icon-calendar_expired:before {
  content: "\e964";
  color: #e28200;
}
.icon-check_square:before {
  content: "\e962";
  color: #00d663;
}
.icon-close_square:before {
  content: "\e963";
  color: #ff4646;
}
.icon-repeat_icon:before {
  content: "\e95e";
}
.icon-date_time_icon:before {
  content: "\e958";
}
.icon-email_tracking_modal:before {
  content: "\e959";
}
.icon-event_name_icon:before {
  content: "\e95a";
}
.icon-group_name_icon:before {
  content: "\e95b";
}
.icon-group_name_modal_icon:before {
  content: "\e95c";
}
.icon-meno_icon:before {
  content: "\e95d";
}
.icon-user_rating:before {
  content: "\e95f";
}
.icon-event_calendar_inactive:before {
  content: "\e960";
}
.icon-event_calendar_active:before {
  content: "\e961";
}
.icon-summary_setting_active:before {
  content: "\e956";
}
.icon-summary_setting_inactive:before {
  content: "\e957";
}
.icon-policy_header_menu:before {
  content: "\e954";
}
.icon-terms_header_menu:before {
  content: "\e955";
}
.icon-lock:before {
  content: "\e952";
}
.icon-camera:before {
  content: "\e953";
}
.icon-help:before {
  content: "\e94c";
}
.icon-maintain:before {
  content: "\e94d";
}
.icon-policy_active:before {
  content: "\e94e";
}
.icon-policy_inactive:before {
  content: "\e94f";
  color: #7f7f7f;
}
.icon-terms_active:before {
  content: "\e950";
}
.icon-terms_inactive:before {
  content: "\e951";
  color: #7f7f7f;
}
.icon-leave_evaluation_modal:before {
  content: "\e94b";
}
.icon-linear_summary:before {
  content: "\e94a";
}
.icon-reset_pass:before {
  content: "\e948";
}
.icon-data_not_found:before {
  content: "\e949";
}
.icon-leave_evaluation:before {
  content: "\e947";
}
.icon-evaluation_result_inactive:before {
  content: "\e944";
  color: #7f7f7f;
}
.icon-evaluation_result_active:before {
  content: "\e945";
}
.icon-evaluation_registration_detail:before {
  content: "\e940";
}
.icon-evaluation_registration_active:before {
  content: "\e941";
}
.icon-evaluation_registration_inactive:before {
  content: "\e942";
  color: #7f7f7f;
}
.icon-unit_active:before {
  content: "\e93d";
}
.icon-unit_inactive:before {
  content: "\e93e";
  color: #7f7f7f;
}
.icon-unit_template:before {
  content: "\e93c";
}
.icon-toast_warning:before {
  content: "\e93b";
  color: #f0a337;
}
.icon-toast_danger:before {
  content: "\e938";
  color: #ff4646;
}
.icon-toast_info:before {
  content: "\e939";
  color: #2e69db;
}
.icon-toast_success:before {
  content: "\e93a";
  color: #28bf6e;
}
.icon-error_time_out:before {
  content: "\e934";
}
.icon-error_401:before {
  content: "\e931";
}
.icon-error_403:before {
  content: "\e932";
}
.icon-error_404:before {
  content: "\e933";
}
.icon-user_leave_modal:before {
  content: "\e930";
}
.icon-info_modal:before {
  content: "\e92d";
}
.icon-trash_modal:before {
  content: "\e92e";
}
.icon-warning_modal:before {
  content: "\e92f";
}
.icon-create_unit:before {
  content: "\e92c";
}
.icon-context_menu:before {
  content: "\e92a";
}
.icon-unit_list:before {
  content: "\e92b";
}
.icon-error_modal:before {
  content: "\e929";
  color: #FF4646;
}
.icon-user_add:before {
  content: "\e927";
}
.icon-user_leave:before {
  content: "\e928";
}
.icon-people_inactive:before {
  content: "\e925";
  color: #7f7f7f;
}
.icon-people_active:before {
  content: "\e926";
}
.icon-error:before {
  content: "\e923";
  color: #ff4646;
}
.icon-sort_table_desc:before {
  content: "\e91f";
}
.icon-sort_table_asc:before {
  content: "\e922";
}
.icon-close_circle_bold:before {
  content: "\e91e";
  color: #7f7f7f;
}
.icon-add:before {
  content: "\e91d";
}
.icon-arrow_down:before {
  content: "\e966";
}
.icon-filter:before {
  content: "\e91a";
}
.icon-user_active:before {
  content: "\e91b";
}
.icon-user_inactive:before {
  content: "\e91c";
  color: #7f7f7f;
}
.icon-chevron_right:before {
  content: "\e911";
}
.icon-arrow_up:before {
  content: "\e910";
}
.icon-data_active:before {
  content: "\e90e";
}
.icon-data_inactive:before {
  content: "\e919";
}
.icon-arrow_left:before {
  content: "\e917";
}
.icon-add_circle:before {
  content: "\e916";
}
.icon-trash:before {
  content: "\e914";
}
.icon-edit:before {
  content: "\e915";
}
.icon-arrow_right:before {
  content: "\e900";
}
.icon-dark_mode_active:before {
  content: "\e901";
}
.icon-dark_mode_inactive:before {
  content: "\e902";
}
.icon-dashboard_active:before {
  content: "\e903";
}
.icon-dashboard_inactive:before {
  content: "\e904";
}
.icon-eye_slash:before {
  content: "\e906";
}
.icon-eye:before {
  content: "\e907";
}
.icon-logo_full:before {
  content: "\e909";
}
.icon-logo_icon:before {
  content: "\e90a";
}
.icon-logout:before {
  content: "\e90b";
}
.icon-note:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e912";
}
.icon-setting:before {
  content: "\e913";
}
.icon-icon_calendar:before {
  content: "\e946";
}
.icon-icon_check:before {
  content: "\e943";
}
.icon-cell:before {
  content: "\e935";
}
.icon-frame:before {
  content: "\e936";
}
.icon-unit:before {
  content: "\e937";
}
.icon-email:before {
  content: "\e924";
}
.icon-warning:before {
  content: "\e921";
}
.icon-close:before {
  content: "\e90f";
}
.icon-tick_circle:before {
  content: "\e90d";
}
.icon-expand:before {
  content: "\e93f";
}
.icon-template_inactive:before {
  content: "\e920";
}
.icon-template_active:before {
  content: "\e969";
}
.icon-create_template:before {
  content: "\e905";
}
.icon-select_template:before {
  content: "\e908";
}
.icon-setting_unit:before {
  content: "\e96a";
}
.icon-create_template_modal:before {
  content: "\e96b";
}
.icon-import_csv:before {
  content: "\e96c";
}
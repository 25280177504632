@import "../mixin";

@mixin sidebar() {
  .sidebar {
    background: $very-dark;

    span {
      color: $dark-gray;
    }

    .sidebar__item {
      &.active {
        border-left-color: $white;

        @include active-icon($white);
      }

      &:hover {
        @include active-icon($white);
      }
    }

    .toggle--item {
      &:hover {
        background: $white;

        &::before {
          color: $black;
        }
      }
    }
  }
}

@mixin auto-size-input {

  // in case input is editing inline in title on such as auto--size component
  .auto--size__input {
    &.mat-form-field-appearance-fill {
  
      // override: valid color as default (onblur)
      &:is(.ng-valid):not(.mat-focused) .mat-form-field-wrapper {
        .mat-form-field-flex {
          border-color: transparent;
        }
      }
  
      // override: hover color (border-color)
      // not apply when disable, focus, error
      &:not(.mat-form-field-disabled):not(.mat-focused):not(.ng-invalid) {
        .mat-form-field-wrapper .mat-form-field-flex:hover {
          border-color: $input-outline-dark;
        }
      }
    }
  }
}

@import "../mixin";

@mixin table() {

  table.mat-table {
    background-color: $very-dark;

    tbody {
      .table-actions .icon-table {
        &:hover {
          background: $icon-action-hover-dark;
        }
      }

      tr.mat-row {

        // line active
        &.highLight {
          background: $line-active-dark;
        }

        // line hover and not apply as hightlight
        &:not(.highLight):not(.empty):hover {
          background: $row-hover-dark;
        }
      }

      .link-text {
        a:hover {
          color: $very-blue-2;
        }
      }
    }

    // override header text's color
    .mat-header-cell {
      color: $white;
    }
  }

  .overlay__scroll {
    background: linear-gradient(0deg, #1E1F23 0%, rgba(21, 21, 21, 0) 100%);
  }
}

@import "variables";

$size-dropdown-horizontal: 1.375rem;
$size-dropdown-vertical: 2.875rem;
$dropdown-max-height: 15rem;
$content-dropdown-max-height: 7.5rem;

@mixin selected-item {
  background-color: $line-active-light;

  .mat-option-text {
    font-weight: bold;
  }
}

@mixin selected-item-multiple {
  background-color: transparent;

  .mat-option-text {
    font-weight: normal;
  }
}

.cdk-overlay-container {
  .cdk-overlay-pane {
    .mat-select-panel-wrap {

      .select--search__panel,
      .select__panel {
        min-width: calc(100% + 3.75rem) !important;
        margin: $size-dropdown-vertical 0;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        max-height: $dropdown-max-height;
        overflow: hidden;
        padding-bottom: 0.5rem;
        margin-left: -2px;

        .form__section {
          padding: 0.5rem 1rem;
          background-color: $very-light-gray1;

          // input type search
          .mat-form-field-type-mat-input {
            width: 100%;

            .mat-form-field-wrapper {
              .mat-form-field-flex {
                background-color: $white;

                .mat-form-field-prefix {
                  margin-right: 0.5rem;

                  .search--icon {
                    font-size: $size-icon;
                  }
                }
              }
            }
          }
        }

        // list optional
        .box--value {
          overflow: auto;
          max-height: $content-dropdown-max-height;
          margin-right: 0.5rem;

          padding-left: 1.5rem;
          padding-top: 0.5rem;

          .divider {
            border-top: 1px solid $light-gray4;
          }

          .max-selection__guide {
            display: flex;
            column-gap: 1rem;
            padding: 0.5rem;
            line-height: 1.5rem;
            color: $dark-gray;

            .mat-error {
              color: $error;
            }

          }
        }

        .select__box {
          padding: 0.75rem 1.5rem;
        }

        .mat-checkbox {
          width: 100%;

          .mat-checkbox-frame {
            outline: none;
            border-radius: 4px;
            border: 1px solid $gray;
          }

          .mat-checkbox-ripple {
            width: $size-icon;
            height: $size-icon;
            left: calc(50% - (#{$size-icon} / 2));
            top: calc(50% - (#{$size-icon} / 2));
          }
        }

        .mat-option {
          height: auto;
          line-height: initial;
          padding: 0.5rem;
          background: transparent;
          border-radius: 0.5rem;

          span.mat-option-text {
            display: flex;
            text-overflow: unset;
            overflow: unset;
            white-space: initial;
            word-break: break-word;
            min-height: 1.5rem;
          }

          .mat-pseudo-checkbox {
            width: $size-icon;
            height: $size-icon;
            border-radius: 4px;
            border-width: 1px;
            color: $gray;
            margin-right: 0.75rem;

            &.mat-pseudo-checkbox-checked {
              background-color: $secondary;
            }

            &::after {
              // styling feeling width $size-icon
              top: 4px;
              left: 3px;
              width: 14px;
              height: 6px;
              color: $white;
            }

            &.mat-pseudo-checkbox-disabled {
              background-color: $very-dark-mostly-black; // TODO: thangnm - Update this base on design for both theme
            }
          }

          &.empty {
            pointer-events: none;

            .mat-pseudo-checkbox {
              display: none;
            }

            span {
              justify-content: center;
            }
          }

          &:hover {
            background-color: $row-hover-light;
          }

          &.mat-selected {
            @include selected-item();
          }

          &.option--has-description {
            align-items: flex-start;

            span.mat-option-text {
              flex-direction: column;
              align-items: flex-start;

              span.text-description {
                border-radius: 40px;
                border: 0.5px solid;
                font-size: 7px;
                line-height: 9px;
                padding: 2px 4px;
              }
            }
          }

        }

        &.multiple {
          margin: $size-dropdown-vertical $size-dropdown-horizontal;

          .mat-option {
            &.mat-selected {
              @include selected-item-multiple();
            }
          }
        }

        &.scroll {
          overflow: auto;
          padding-left: 1.0rem;
          padding-top: 0.5rem;
          max-height: 20vh;
        }
      }
    }
  }

}

.select--search__section,
.select__section {

  .mat-form-field-underline {
    display: none;
  }

  .mat-select-arrow {
    opacity: 0;
    margin: 0;
  }
}

@mixin button {
  button {
    background: $secondary;

    &.button--primary {
      background: transparent;
      color: $white;

      &:hover {
        background: $very-dark-gray4;
      }
    }

    &:hover {
      background: $vivid-blue;
    }

    &:disabled,
    [disabled] {
      background: $gray;
      color: $dark-gray;
    }
  }

  .button--secondary,
  .button--secondary--bold {
    background: transparent;
    color: $white;

    &:hover {
      background: $very-dark-gray4;
    }
  }

  .button--icon {
    &:hover {
      background-color: $light-gray3;
      color: $black;
    }
  }
}

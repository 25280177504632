@import "variables";
@import "mixin";

$left-size: 1.5rem;

.sidebar {
  position: fixed;
  z-index: 600;
  top: 0;
  bottom: 0;
  left: 0;

  width: $sidebar-extended-width;
  max-width: $sidebar-extended-width;

  transform: translate3d(0, 0, 0);

  &:not(.sidebar--small) {
    transition: max-width 0.5s ease-in-out;
  }

  display: flex;
  flex-direction: column;
  background: $white;

  span {
    color: $dark-gray;
  }

  .sidebar__logo-container {
    padding-top: 1.875rem;
    padding-left: $left-size;
    padding-bottom: 0.75rem;

    .sidebar__logo {
      line-height: 1;

      a {
        text-decoration: none;
  
        i {
          font-size: $size-icon;
        }
      }
    }
    
  }

  .sidebar--inner-scroll {
    height: 100%;
    width: 100%;

    overflow: auto;
    overflow-x: hidden;
  }

  &.sidebar--small {
    max-width: $sidebar-compact-width;
    transition: max-width 0.3s ease-in-out;

    .sidebar__logo-container {
      display: flex;
      justify-content: center;
      padding: 1.875rem 0 3.75rem 0;

      .sidebar__logo  {
        img {
          max-width: 2.5rem;
        }
      }
    }

    .sidebar__item {
      a {
        display: flex;

        span {
          display: none;
        }
      }
    }
  }

  .sidebar__item {
    position: relative;
    display: flex;
    align-items: center;
    height: 3rem;
    border-left: 0.25rem solid transparent;
    
    &.active {
      border-left-color: $black;

      @include active-icon();
    }

    &:hover {
      @include active-icon();
    }

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-left: $left-size - 0.25rem;
      text-decoration: none;

      i::before {
        color: $dark-gray;
        font-size: $size-icon;
      }
    }

    a>span,
    button>span {
      text-align: left;
      margin-left: 1rem;
      opacity: 1;
      white-space: nowrap;
      overflow: hidden;
      transition: opacity 0.7s ease-in-out;
    }

    .sidebar__label {
      white-space: normal !important;
    }
  }

  .side--bar__action {
    position: fixed;
    bottom: 1rem;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: $left-size;

    &.expanded {
      width: $sidebar-extended-width;
      
    }

    &.collapse {
      padding-left: 1rem;
      transition: padding-left 0.5s linear;
    }
  }

  .toggle--item {
    border: 1px solid $light-gray2;
    padding: 7px;
    border-radius: 8px;

    &::before {
      font-size: $size-icon;
    }
  
    &:hover {
      background: $light-gray3;
    }
  }
}


@mixin unit() {
  .unit-container {
    background: $very-dark;

    .unit-content {
      border-color: $very-dark-gray;

      .unit-content-right {
        border-left-color: $very-dark-gray;
        
        .unit-form-action-keep {
          background: $row-hover-dark;

          button {
            &.button--keep {
              border: 1px solid $white;
              color: $white;

              &[disabled] {
                color: $dark-gray;
                border-color: $dark-gray;
              }
            }
          }
        }
      }
    }

    .unit-actions {
      .actions-wrapper {
        .start {
          .button-unit-detail-setting, .button-unit-select-template {
            background: transparent;
            color: $white;
            border: 1px solid $white;
  
            &:hover {
              background: $very-dark-gray4;
            }
          }
        }
      }
    }
  }
}
@import "../mixin";

@mixin pagination() {
  .mat-paginator {
    background-color: $very-dark;
    .mat-paginator-outer-container {
      background-color: $very-dark;
    }
  }

  .paginator {
    .paginator-actions {
      .paginator-page-numbers .paginator-page-number {
        &.clickable:hover {
          cursor: pointer;
          background: $background_button_dark_hover;
          border-radius: 32px;
        }

        &.active {
          background: $white;
          color: $black;
        }
      }

      button {
        &[disabled] {
          color: $dark-gray;
          background-color: $very-dark;
        }
  
        &:not(:disabled) {
          background-color: $very-dark;
          color: $white;
        }

        &:hover {
          background: $background_button_dark_hover;
        }
      }
    }

    .paginator-page-size .mat-form-field
    {
      &.mat-paginator-page-size-select {
        height: 48px;
        background: $background_select_dark;
      }

      .mat-form-field-wrapper .mat-form-field-infix .mat-select-arrow-wrapper {
        background: url('/assets/images/pagination/arrow-down-dark.svg') 50% 50% no-repeat;
        background-size: 16px 16px
      }
    }
  }

  .cdk-overlay-connected-position-bounding-box {
    .cdk-overlay-pane {
      .mat-select-panel-wrap {
        .mat-select-panel {
          background: $background_select_dark;
        }  
      }
    }
  }
}

@mixin dialog() {
  .cdk-overlay-pane {
    .mat-dialog-container {
      background: $very-dark;

      .mat-dialog-actions,
      .actions {
        background: $very-dark-gray3;
      }
    }
  }

  .cdk-overlay-container {
    .backdrop--dialog__cdk {
      &.cdk-overlay-backdrop-showing {
        opacity: 0.5;
      }
    }
  }

}

@import "variables";
@import "mixin";


/* ----------------------------------------- */
/*        Class global <=> inline            */
/* ----------------------------------------- */
// Display
.d {
  &__none {
    display: none !important;
  }

  &__inline-block {
    display: inline-block !important;
  }
}

.visibility {
  &__hidden {
    visibility: hidden !important;
  }

  &__visible {
    visibility: visible !important;
  }
}

// Cursor
.clicked {
  cursor: pointer !important;
}

// Fonts
.font--bold {
  font-weight: bold !important;
}

.text-scrolling {
  @include text-scrolling();
}

/* ----------------------------------------- */
/*             Styling global                */
/* ----------------------------------------- */
.title__inline {

  a,
  span,
  p,
  label,
  input {
    @include font-size($xxs-large);
    font-weight: bold;
  }
}

.border--site__vertical {
  $value: 1px solid $light-gray4;
  border-top: $value;
  border-bottom: $value;
}

.mat-form-field-appearance-fill {
  .mat-form-field-wrapper {

    // default setting
    .mat-form-field-flex {

      input,
      textarea {
        &.mat-input-element {
          caret-color: $black;
          margin-top: 0;
          &:not([disabled])::placeholder {
            color: $gray;
          }
          &:placeholder-shown {
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

/** MATERIAL CHECKBOX - CUSTOM STYLING **/
.mat-checkbox {
  .mat-checkbox-inner-container {
    width: 1.5rem;
    height: 1.5rem;

    .mat-checkbox-frame {
      outline: solid 1px $input-outline-light;
      transition: none;
      border-width: 0;
      border-style: none;

      @include mat-checkbox-frame();
    }


    .mat-checkbox-ripple.mat-focus-indicator {
      display: none;
    }
  }

  &.mat-checkbox-checked {
    .mat-checkbox-checkmark {
      .mat-checkbox-checkmark-path {
        stroke: $white !important;
      }
    }

    & .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background {
      background-color: $secondary;
    }
  }

  &:not(.mat-checkbox-checked):hover .mat-checkbox-layout {
    .mat-checkbox-inner-container .mat-checkbox-frame {
      outline-color: $input-outline-focus;
    }
  }
}

body.dark .mat-checkbox {
  .mat-checkbox-frame {
    outline-color: $dark-gray;
  }
}
/** MATERIAL RADIO - CUSTOM STYLING **/
.mat-radio-button {
  .mat-radio-container {
    .mat-radio-outer-circle {
      border-color: $input-outline-light;
    }
  
    .mat-radio-ripple.mat-focus-indicator {
      display: none;
    }
  }
  &:not(.mat-radio-checked) {
    .mat-radio-container:hover .mat-radio-outer-circle {
      border-color: $input-outline-focus;
    }
  }
}

body.dark .mat-radio-container {
  .mat-radio-outer-circle {
    border-color: $dark-gray;
  }
}

/** MATERIAL SELECT PANEL ON FITER PANEL - CUSTOM STYLING **/
.cdk-overlay-container {
  .cdk-overlay-pane {
    .mat-select-panel-wrap {
      .mat-select-panel.filter--select-panel__custom {

        &.select--search__panel.multiple {
          // override default
          margin-left: 23px !important;
          margin-right: 23px !important;
          min-width: calc(100% + 2.25rem) !important;
        }
      }
    }
  }
}

.hidden-visibiliy {
  visibility: hidden;
}

/** INPUT TYPE='color' - CUSTOM STYLING **/
div.input-color {
  overflow: hidden;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  
  &:hover {
    border: 1px solid $dark-gray;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  }

  &:focus {
    border-color: $primary;
  }

  input[type="color"] {
    border: 0;
    min-width: 3rem;
    min-height: 3rem;
    cursor: pointer;
    margin: 0;
    transform: translate(-25%, -25%);
  }
}

@import "mixin";
@import "variables";

@mixin size-radio {
  width: $size-icon;
  height: $size-icon;
  border-width: 1px;
}

.custom--radio__group {
  display: flex;
  gap: 0.75rem;

  &.column {
    flex-direction: column;
  }

  .mat-radio-button {

    .mat-radio-container {
      @include size-radio();

      .mat-radio-outer-circle {
        @include size-radio();
      }

      .mat-radio-inner-circle {
        @include size-radio();
      }
    }

    .mat-radio-label {
      align-items: initial;

      .mat-radio-label-content {
        white-space: pre-line;
      }
    }

    &.mat-accent {
      .mat-radio-inner-circle {
        background-color: $secondary;
      }

      &.mat-radio-checked {
        .mat-radio-outer-circle {
          border-color: $secondary;
        }
      }
    }
  }
}

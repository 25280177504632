// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// https://css-tricks.com/introducing-sass-modules/
@use '@angular/material'as mat;
@use './assets/scss/palette'as palette;

// project
@import "./assets/scss/index";
@import "./assets/scss/token/index";

// icon moon
@import "./assets/scss/iconmoon";


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$engineering-evaluation-primary: mat.define-palette(mat.$light-blue-palette);
$engineering-evaluation-accent: mat.define-palette(mat.$pink-palette,
  A200,
  A100,
  A400);

// The warn palette is optional (defaults to red).
$engineering-evaluation-warn: mat.define-palette(mat.$amber-palette);

// Dark palettes
$engineering-evaluation-dark-primary: mat.define-palette(palette.$primary-palette);
$engineering-evaluation-dark-accent: mat.define-palette(palette.$accent-palette,
  A200,
  A100,
  A400);

// The warn palette is optional (defaults to red).
$engineering-evaluation-dark-warn: mat.define-palette(palette.$warn-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$engineering-evaluation-theme: mat.define-light-theme((color: (primary: $engineering-evaluation-primary,
      accent: $engineering-evaluation-accent,
      warn: $engineering-evaluation-warn,
    ),
  ));

$engineering-evaluation-dark-theme: mat.define-dark-theme((color: (primary: $engineering-evaluation-dark-primary,
      accent: $engineering-evaluation-dark-accent,
      warn: $engineering-evaluation-dark-warn,
    ),
  ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($engineering-evaluation-theme);

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $primary-font;
  background: #F7F8FA;
  font-size: $normal; // default
}

body.dark {
  @include mat.all-component-colors($engineering-evaluation-dark-theme);
  background: $black ;
  color: $white;
}

/* 1. Use a more-intuitive box-sizing model.*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* 2. Remove default margin and apply primary-font for whole */
* {
  margin: 0;
  font-family: $primary-font;
}

/* 3. Allow percentage-based heights in the application */
html,
body {
  height: 100%;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/* 6. Improve media defaults */
img,
picture,
video,
canvas,
svg {
  display: flex;
  max-width: 100%;
}

/* 8. Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* 9. Create a root stacking context */
#root,
#__next {
  isolation: isolate;
}

ol,
ul {
  padding-left: 0;
}


@import "mixin";

*::-webkit-scrollbar {
  width: calc(0.5rem + (#{$scrollbar-size-space} * 2));
}

*::-webkit-scrollbar-thumb {
  height: 50%;
  background-color: $gray;
  @include custom-scrollbar();
}

*::-webkit-scrollbar-track {
  background: #F2F2F2;
  @include custom-scrollbar();
}

.custom--scrollbar__haft--space {
  &::-webkit-scrollbar-thumb {
    @include custom-scrollbar(false);
  }

  &::-webkit-scrollbar-track {
    @include custom-scrollbar(false);
  }
}

@mixin input {
  .form__section {
  
    // override to body.dark
    .mat-form-field-appearance-fill {
      // override: valid color as default (onblur)
      &:not(.mat-focused) .mat-form-field-wrapper {
        .mat-form-field-flex {
          border-color: $input-outline-dark;
        }
      }
  
      // override: hover color (box-shadow)
      // not apply when disable, focus, error
      &:not(.mat-form-field-disabled):not(.mat-focused):not(.ng-invalid) {
        .mat-form-field-wrapper .mat-form-field-flex:hover {
          box-shadow: 0px 0px 16px $input-outline-hover-shadow-dark;
        }
      }

      // override: disabled color
      &.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-flex {
        background-color: $input-disable-dark;
        border-color: transparent;
      }
    }
  }
}

.form__section {
  
  // override to body.dark
  .mat-form-field-appearance-fill {
    .mat-form-field-wrapper {
      .mat-form-field-wrapper {
  
        // override: default setting on light mode
        .mat-form-field-flex {
          background-color: $black;
          border-color: $input-outline-dark;
        }
      }
    }
  }
}


$content-max-height: 10rem;

$panel-border-radius: 8px !default;

.cdk-overlay-pane {
  .mat-autocomplete-panel {
    max-height: $content-max-height;
    height: auto;
    padding: 0.5rem;
    margin-top: 0.25rem;
    border-radius: $panel-border-radius;

    .mat-option {
      height: auto;
      line-height: initial;
      padding: 0.5rem 1rem;

      span {
        display: flex;
        text-overflow: unset;
        overflow: unset;
        white-space: initial;
      }

      &.empty {
        pointer-events: none;

        span {
          justify-content: center;
        }
      }
    }
  }

}

// IMPORTANT - DO NOT DELETE
// COLOR SYSTEM STYLE GUIDE
$primary: #6200ee;
$secondary: #0084FF;

$gray4: #2b3838;

$white: #ffffff;
$black: #000000;
$error: #FF4646;
$warning: #F0A337;
$info: #2E69DB;
$success: #28BF6E;
$required: #EA1616;

$gray: #CCCCCC;
$dark-gray: #7F7F7F;

$very-light-gray: #F2F2F2;
$very-light-gray1: #F7F8FA;
$very-light-gray2: #E0E0E0;
$very-light-gray3: #E3E3E3;
$very-light-gray4: #F7F7F7;

$very-dark: #1E1F23;
$very-dark-gray: #333333;
$very-dark-gray2: #707070;
$very-dark-gray3: #2D2F34;
$very-dark-gray4: #3E4046;
$very-dark-gray5: #4A4A4A;
$very-dark-gray6: #383A40;
$very-dark-gray7: #242429;
$very-dark-gray8: #ACACAC;
$very-dark-gray9: #43464E;
$very-dark-gray10: #525252;

$very-dark-mostly-black: #00000014;
$very-dark-mostly-black2: #121212;

$very-dark-blue: #25262A;
$very-dark-blue1: #192f46;
$very-blue-1: #0A5AA7;
$very-blue-2: #79B1E6;
$very-blue-3: #F0F5FF;
$very-blue-4: #EFF4F8;

$very-pale: #ffefef;

$lime-green: #00D663;
$lime-green1: #F0FFF7;

$vivid-blue: #2295FF;
$light-gray: #EDEEEF;
$light-gray2: #EDF0F7;
$light-gray3: #EBEBEB;
$light-gray4: #DCE2EF;

$very-orange: #E28200;

// fonts
$primary-font: 'Noto Sans JP', sans-serif;

// fonts size, padding, margin
$tiny: 4px;
$x-tiny: 6px;
$small: 8px;
$xxs-small: 10px;
$x-small: 12px;
$medium: 14px;
$normal: 16px;
$large: 18px;
$xxs-large: 20px;
$xs-large: 24px;
$x-large: 30px;
$xx-large: 32px;
$xxx-large: 40px;
$xl-large: 46px;
$xxl-large: 50px;

// line height
$line-height-small: 16px;
$line-height-medium: 20px;
$line-height-normal: 24px;
$line-height-large: 28px;
$line-height-xxs-large: 30px;
$line-height-xs-large: 33px;
$line-height-x-large: 36px;
$line-height-xx-large: 40px;
$line-height-xxx-large: 45px;
$line-height-xl-large: 58px;
$line-height-xxl-large: 60px;

/**
 * Font weight - ref
 * https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
 * https://fonts.google.com/noto/specimen/Noto+Sans+JP
 */
$light-weight: 300;
$normal-weight: 400; // Regular
$medium-weight: 500;
$semi-bold-weight: 600;
$bold-weight: 700;
$extra-bold-weight: 800;

// header
$header-height: 5rem;

// sidebar
$sidebar-compact-width: 4.5rem;
$sidebar-extended-width: 18.75rem;

// size-icon
$size-icon-xss-small: 0.5rem;
$size-icon-xs-small: 0.75rem;
$size-icon-small: 1rem;
$size-icon: 1.5rem;
$size-icon-x-large: 1.875rem;
$size-icon-xxl-large: 4.5rem;

// color input
$input-gray: #F7F7F7;
$input-black: #000000;


$input-outline-light: #CCCCCC;
$input-outline-dark: #7F7F7F;

$input-outline-invalid: $error;
$input-disable-light: #CCCCCC;
$input-disable-dark: #121212;
$input-outline-focus: #0084FF;

$input-outline-hover-shadow-light: #00000014; // #0000003e
$input-outline-hover-shadow-dark: #FFFFFF14; // #ffffff2e
$input-outline-focus-shadow: #0084FF14;


// color button
$button_black: #000000;
$button_blue: #0084FF;
$button_disable_dark: #CCCCCC;
$button_hover_dark: #2295FF;

// pagination select
$background_select: #F7F8FA;
$background_select_hover: #EDEEEF;
$background_select_dark: #2D2F34;
$background_select__dark_hover:#3E4046;
$background_button_dark_hover: #2A2B2E;

// list
$row-hover-light: #F7F7F7;
$row-hover-dark: #25262A;
$icon-action-hover-light: #EBEBEB;
$icon-action-hover-dark: #3C3C3C;

// any item | row is selected state (active state)
$line-active-dark: #192F46;
$line-active-light: #E5F2FF;

// scroll top
$background_scroll_top_light: #FFFFFF;
$background_scroll_top_dark: #1E1F23;
$background_scroll_top_light_hover: #FAFAFA;
$background_scroll_top_dark_hover: #333333;

// filter table
$background_filter_table_light: #F7F8FA;
$background_filter_table_dark: #2D2F34;


// custom scrollbar
$scrollbar-size: 1.5rem;
$scrollbar-size-mini: 0.25rem;
$scrollbar-size-space: var(--scrollbar-size-space);

// form-field
$wrapper-padding-bottom: 1.34375em;

// main
$header-size: 4rem;
$space-size: 1.875rem;

// snackbar
$background_snackbar_error: #FFEFEF;
$background_snackbar_warning: #FFF9F0;
$background_snackbar_info: #F0F5FF;
$background_snackbar_success: #F0FFF7;


// table
$height-header-row: 3.5rem;

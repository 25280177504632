@import "variables";

/* Box */
@mixin box($width: 100%, $height: 100%) {
  width: $width;
  height: $height;
}

/** Flexbox Toolkit */
@mixin flex($direction: row, $justify-content: center, $align-items: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-column {
  @include flex-center;
  flex-direction: column;
}

@mixin flex-center-vertical {
  display: flex;
  align-items: center;
}

@mixin flex-center-horizon {
  display: flex;
  justify-content: center;
}

/* Font Size */
@mixin font-size($font-size: $font-size-base, $line-height: normal, $letter-spacing: normal) {
  font-size: $font-size;

  @if $line-height==normal {

    /** Setup for https://app.clickup.com/t/3721736/TC-423 */
    @if($font-size < $xxs-large) {
      line-height: $line-height-normal;
    }

    @else if($font-size < $xs-large) {
      line-height: $line-height-xxs-large;
    }

    @else {
      line-height: $line-height-x-large;
    }
  }

  @else {
    line-height: $line-height / $font-size;
  }

  @if $letter-spacing==normal {
    letter-spacing: normal;
  }

  @else {
    letter-spacing: #{$letter-spacing / $font-size}em;
  }
}

/* Visibility */
@mixin fade($type) {
  @if $type=="hide" {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 1s;
  }

  @else if $type=="show" {
    visibility: visible;
    opacity: 1;
    transition: visibility 1s, opacity 1s;
  }
}

/* Active and hover icon */
@mixin active-icon($color: $black) {
  a {
    i::before {
      color: $color;
    }
  }

  span {
    color: $color;
  }
}

@mixin three-dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/** Custom scrollbar */
@mixin custom-scrollbar($isFullSpace: true) {
  background-clip: padding-box;

  @if $isFullSpace {
    border: $scrollbar-size-space solid rgba(0, 0, 0, 0);
    border-radius: 500rem;
  }

  @else {
    border-left: $scrollbar-size-space solid rgba(0, 0, 0, 0);
    border-right: $scrollbar-size-space solid rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
}

/** Background image */
@mixin background-image($bgRepeat: unset,
  $bgPosition: unset,
  $bgSize: unset,
  $width: unset,
  $height: unset) {
  width: $width;
  height: $height;
  background-repeat: $bgRepeat;
  background-position: $bgPosition;
  background-size: $bgSize;
}

/** Block total info (eg. メンバー件数： ???人)**/
@mixin total-info-block ($isLight: true) {
  @if $isLight {

    color: $dark-gray;
    @include font-size($medium);

    .font--bold {
      color: black;
    }
  }

  @else {
    color: $gray;

    .font--bold {
      color: white;
    }
  }
}

@mixin mat-checkbox-frame($background-color: transparent) {
  background-color: $background-color;
}

@mixin user-role($font-size: $medium, $border-width: 1px, $padding: 0 0.75rem, $font-weight: normal) {
  white-space: nowrap;
  padding: $padding;
  border-radius: 1rem;
  border: $border-width solid $lime-green;
  color: $lime-green;
  font-size: $font-size;
  font-weight: $font-weight;
  cursor: default;
}

@mixin text-scrolling ($width: 100%) {
  width: $width;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  // with including text animation
  &.scroll {

    &:hover,
    &:focus {
      span {
        display: inline-block;
        animation-name: scroll-text;
        animation-duration: 5s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-direction: normal;

      }
    }

    @keyframes scroll-text {
      0% {
        transform: translateX(0%);
      }

      90% {
        transform: translateX(-40%);
      }

      95% {
        transform: translateX(0%);
      }

      100% {
        transform: translateX(0%);
      }
    }
  }
}

@mixin custom-vertical-scrolling($mustOverride: false) {

  /** Chrome or Chromium (ver.94+) ect **/
  @supports (scrollbar-gutter: stable) {
    // default hide scrollbar
    @if $mustOverride {
      overflow-y: hidden !important;
    } @else {
      overflow-y: hidden;
    }

    // prevent layout shift by scrollbar
    scrollbar-gutter: stable;

    // show when hover
    &:hover {
      @if $mustOverride {
        overflow-y: auto !important;
      } @else {
        overflow-y: auto;
      }
    }
  }

  // Safari, FF etc: always show (TODO: will trick later)
  // overflowing container is position:absolute so the preventing layout shift is not working well
  @supports not (scrollbar-gutter: stable) {
    // default show scrollbar
    overflow-y: scroll;
  }
}

/** A Specific table display **/
// Define common styling which is used such as at detail screen
@mixin item-selection-table($isLight: true) {
  @if $isLight {
    .item-selection-table {
      border-collapse: collapse;

      tr {
        border-bottom: 1px solid $light-gray4;

        &:first-child,
        &:last-child {
          border-bottom: none;
        }

        th {
          text-align: left;
          padding: 0;

          &.choices {
            width: auto;
          }

          &.point {
            width: 21%;
          }

          &.jump-session {
            width: 30%;
          }
        }

        td {
          padding: 8px 8px 8px 0;

          &.choices {
            word-break: break-all;
          }

          &.point {
            vertical-align: text-top;
          }
        }

        th,
        td {
          &:not(:last-of-type) {
            padding-right: 1.5rem;
          }
        }
      }
    }

    .item-label,
    .item-selection-table th {
      color: $dark-gray;
      font-size: $x-small;
      line-height: $xxs-large;
      font-weight: normal;
    }
  }
  @else {
    .item-selection-table {
      tr {
        border-bottom-color: $very-dark-gray;
      }
    }
  }
}

@mixin link-text {
  a {
    text-decoration: underline;

    &:hover {
      color: $secondary;
    }
  }
}

// Customize mat-calendar
$hover-color: #DFE0E2;
$padding-calendar-content-picker: 0 1.5rem 1.5rem 1.5rem;

@mixin mat-calendar-custom ($font-size: $normal, 
  $background-today: transparent,
  $padding-header: 0.5rem 1.5rem 0 1.5rem,
  $padding-content: $padding-calendar-content-picker) {

  .mat-calendar {

    .mat-calendar-header {
      padding: $padding-header;
    }

    button {
      background-color: transparent;
      font-weight: normal;
      font-size: $font-size;

      &.mat-calendar-period-button .period--text {
        font-size: inherit;
      }
    }

    .mat-calendar-content {
      // override default padding 8px mat-calendar-content
      padding: $padding-content;

      mat-month-view {
        .mat-calendar-table-header {
          >tr {
            >th {
              span {
                @include font-size($font-size);
              }

              &:nth-child(1) {
                span {
                  color: $error;
                }
              }
            }
          }
        }


        .mat-calendar-body {
          >tr {
            >td {
              &:nth-child(1) {
                .mat-calendar-body-cell-content {
                  color: $error !important;
                }
              }
            }
          }

          .mat-calendar-body-label {
            opacity: 0;
          }

          .mat-calendar-body-label[colspan="7"] {
            display: none;
          }

          .mat-calendar-body-cell-container {

            button {
              padding: initial;
            }

            .mat-calendar-body-in-range::before {
              background-color: $very-light-gray3;
            }
          }
        }
      }

      mat-multi-year-view,
      mat-year-view {

        .mat-calendar-body-cell-container {
          .mat-calendar-body-cell {
            .mat-calendar-body-cell-content {
              border-radius: 8px;
              box-shadow: none;
              border: 1px solid transparent;
              font-size: $font-size;
              width: auto;
              height: auto;
              padding: 3px 6px;
            }
          }
        }
      }

      mat-year-view {
        .mat-calendar-body {
          .mat-calendar-body-label {
            display: none;
          }
        }
      }

      // all view mode
      .mat-calendar-table-header {
        .mat-calendar-table-header-divider {
          &::after {
            background: $gray;
          }
        }
      }

      .mat-calendar-body-cell-container {
        .mat-calendar-body-cell {
          &, &.mat-calendar-body-active {

            .mat-calendar-body-cell-content {
              box-shadow: initial;
  
              &.mat-calendar-body-today {
                background-color: $background-today;
                color: $black;
                border-color: $secondary;
              }
              
              &.mat-calendar-body-selected {
                background: $black;
                color: $white;
                border-color: transparent;

                &.mat-calendar-body-today {
                  border-color: $secondary;
                  box-shadow: inset 0px 0px 0px 1px $white;
                }
              }
            }
          }

          &:hover {
            &, &:not(.mat-calendar-body-active) {
              .mat-calendar-body-cell-content {
                &:not(.mat-calendar-body-selected):not(.mat-calendar-body-today) {
                  background-color: $hover-color;
                }
                
              }
            }
          }

          &.mat-calendar-body-active {
            .mat-calendar-body-cell-content {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

@mixin mat-calendar-custom-dark($background-today: transparent, $hover-body-cell-content) {
  .mat-calendar {
    .mat-calendar-content {
      mat-month-view {
        
        .mat-calendar-body {

          .mat-calendar-body-cell-container {

            .mat-calendar-body-in-range::before {
              background-color: $very-dark-blue;
            }
          }
        }
      }

      // all view mode
      .mat-calendar-table-header {
        .mat-calendar-table-header-divider {
          &::after {
            background: $very-dark-gray4;
          }
        }
      }

      .mat-calendar-body-cell-container {
        .mat-calendar-body-cell {
          &, &.mat-calendar-body-active {
            .mat-calendar-body-cell-content {

              &.mat-calendar-body-today {
                background-color: $background-today;
                color: $white;
              }
              
              &.mat-calendar-body-selected {
                background: $white;
                color: $black;

                &.mat-calendar-body-today {
                  border-color: $secondary;
                  box-shadow: inset 0px 0px 0px 1px $black;
                }
              }
            }
          }

          &:hover {
            &, &:not(.mat-calendar-body-active) {
              .mat-calendar-body-cell-content {
                &:not(.mat-calendar-body-selected):not(.mat-calendar-body-today) {
                  background-color: $hover-body-cell-content;
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin text-badge($isLight: true) {
  span {
    @if $isLight {
      border-radius: 2.5rem;
      background: $very-light-gray;
      font-size: $medium;
      line-height: $line-height-medium;
      padding: 0.125rem 0.5rem;
    }
  
    @else {
      background: $very-dark-gray6;
    }
  }
  
}
@import 'mixin';

$breadcrumbHeight : 100px;
$spaceAtFooterHeight : 30px;
$finalSaveAreaHeight : 72px;
$memorySaveAreaHeightWithPadding : 82px;
.unit-container {
  background: $white;
  border-radius: $small;
  padding: 1.5rem;
  height: calc(100vh - #{$breadcrumbHeight + $spaceAtFooterHeight});
  overflow: hidden;

  .unit-content {
    width: 100%;
    border: 1px solid $very-light-gray2;
    border-radius: $small;
    display: flex;
    flex: 1;
    height: calc(100% - $finalSaveAreaHeight);

    .unit-content-left {
      flex: 5;
      padding: $normal;
      //min-width: 418px;
      min-width: 10%;
      max-width: 35%;
      height: 100%;
      overflow-y: auto;

      // force to hidden to not show white corner in dark mode
      overflow-x: hidden;

      &.action-mode {
        .tree-node tree-node-wrapper .node-content-wrapper .tree-node-content {
          min-width: 0  ;
        }
      }
    }

    .unit-content-right {
      flex: 7;
      overflow: hidden;
      height: 100%;
      border-left: 1px solid $very-light-gray2;

      .unit-view-wrapper {
        height: 100%;
        overflow: auto;
      }

      .unit-form-wrapper {
        flex: 1;
        padding: $normal;
        height: calc(100% - $memorySaveAreaHeightWithPadding);
        overflow-x: hidden;
        overflow-y: auto;
      }

      .unit-form-action-keep {
        @include flex($justify-content: space-between);
        padding: $normal;
        background-color: $very-light-gray1;
        border-bottom-right-radius: 8px;

        .save--memory__action {
          @include flex();
          gap: 1rem;

          // toast updated successfully
          span {
            @include flex();
            gap: 0.5rem;
            color: $success;
            font-weight: bold;
            transition:visibility 0.3s linear;

            i::before {
              font-size: $xs-large;
            }
          }
        }

        button {
          &.button--keep {
            background-color: transparent;
            border: 1px solid $black;
            color: $black;

            &[disabled] {
              color: $dark-gray;
              border-color: $gray;
            }
          }
        }
      }

      .unit-view-wrapper {
        .node-name {
          @include font-size($xxs-large);
          font-weight: bold;
          padding: $xs-large $x-small;
          word-break: break-all;
        }
      }

      .form__section {
        form {
          width: 100%;
        }
      }
    }
  }

  .unit-actions {
    .actions-wrapper {
      display: flex;
      padding-top: $xs-large;

      .start {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        gap: 0.5rem;

        .button-unit-detail-setting, .button-unit-select-template {
          background: $white;
          color: $black;
          border: 1px solid $black;

          &:hover {
            background: $very-light-gray;
          }
        }
      }

      .end {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        gap: 0.5rem;
      }
    }
  }

}

.cdk-overlay-pane {
  // default min-width to apply only form--dialog
  &.form--dialog__cdk {
    min-width: 48vw;
  }
  // default min-width to apply only form--dialog
  &.confirm--dialog__cdk {
    min-width: 20rem;
  }

  .mat-dialog-container {
    position: relative;
    padding: 0;
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.122));
    border-radius: 1rem;

    .mat-dialog-content {
      padding: 2rem;
      margin: 0;
    }

    .mat-dialog-actions,
    .actions {
      display: flex;
      gap: 0.5rem;
      background: $very-light-gray;
      padding: 1.5rem 2rem;
      margin: 0;

      button {
        width: fit-content;
      }

      &.small {
        padding: 1rem;
      }
    }

    .actions {
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom-left-radius: var(--theme-border-radius-sm);
      border-bottom-right-radius: var(--theme-border-radius-sm);
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -o-transform: translateZ(0);
      transform: translateZ(0);

      .delete {
        position: absolute;
        display: flex;
        left: 2rem;
        gap: 0.5rem;
        cursor: pointer;
      }

    }

    .log--template,
    .log--container {
      position: absolute;
      bottom: 7rem;
      right: 2rem;
      text-align: end;
      color: $dark-gray;
    }
  }

  &.hidden-overflow {
    .mat-dialog-container {
      overflow: hidden;
    }
  }
}

.cdk-overlay-container {
  .backdrop--dialog__cdk {
    background: $black;

    &.cdk-overlay-backdrop-showing {
      opacity: 0.2;
    }
  }
}

.dialog--secondary {
  .mat-dialog-content {
    @include flex-center-column;
    gap: 2rem;

    span {
      white-space: pre-line;
      text-align: center;
    }

    i {
      &::before {
        font-size: $size-icon-xxl-large;
      }
    }
  }
}

.custom--outside__dialog {
  .mat-dialog-container {
    overflow: initial;
  }
}

@import "../mixin";

@mixin custom-scrollbar-dark {
  *::-webkit-scrollbar-thumb {
    background-color: #535353;
  }

  *::-webkit-scrollbar-track {
    background: #313131;
    @include custom-scrollbar();
  }
}
